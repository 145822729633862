<template>
	<div  class="px-4 py-4">
		<b-card-title class="mt-2">{{ $t("Navbar.Help") }}</b-card-title>
		<div role="tablist" id="help">
			<b-card-text class="my-4 mx-4">{{ $t(helpList.hint, param) }}</b-card-text>
			<b-card v-for="(item, count) in helpList.faq" :key="count" no-body class="mb-1">
				<b-card-header header-tag="header" role="tab">
					<b-button block href="#" v-b-toggle="'accordion-' + count">{{ $t(item.question) }}</b-button>
				</b-card-header>
				<b-collapse :id="'accordion-' + count" :visible='count==0' accordion="my-accordion" role="tabpanel">
					<b-card-body>
						<b-card-text v-html="$t(item.text, param)"></b-card-text>
					</b-card-body>
				</b-collapse>
			</b-card>
		</div>
	</div>
</template>

<script>
export default {
	name: "cg-help",
	data() {
		return {
			// TODO: this param list should be retrieved from a config file
			param: { 
				email: "cybergurucontact@mail.com",
				support: "support@mail.com", 
				config: "config@mail.com", 
				administration: "administration@mail.com" 
			},
			helpList: {
				hint: "Help.Hint",
				// TODO: improve FAQ questions and answers management (from a config...)
				faq: [
					{question: 'Help.Q1', text: 'Help.A1'},
					{question: 'Help.Q2', text: 'Help.A2'},
					{question: 'Help.Q3', text: 'Help.A3'},
					{question: 'Help.Q4', text: 'Help.A4'}
				]
			}
		}
	}
};
</script>

<style lang="less" scoped>
#help {
	.card {
		.card-header {
			background-color: rgba(0, 123, 255, 0.08);
			.btn-block {
				background-color: transparent;
				color: @cg-primary-dark-text-color;
				border: none;
			}
		}
	}
}
</style>